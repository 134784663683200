<template>
  <section>
    <div class="team-component mini-spacer">
      <div class="container">
        <div class="row justify-center">
          <div class="col-sm-10 col-md-9 col-lg-7 col-12">
            <div class="text-center">
               <!-- <h2 class="section-title font-weight-medium"> -->
              <div class="text-h2">
                Аяллын експертүүд
              </div>
              <p class="description">
                Мэргэжлийн аялагч, байгалийн зурагчин, өв соёлыг бүтээгчид
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-13">
          <div
            class="col-md-6 col-lg-3 col-12"
            v-for="(expert, expertsIndex) in experts"
            :key="expertsIndex"
          >
            <div
              class="
                team-card
                overflow-hidden
                mb-15
                v-card v-sheet
                theme--light
                elevation-0
              "
            >
              <div class="social-overlay">
                <v-img :src="expert.imageUrl"></v-img>
                <div class="img-overlay">
                  <ul>
                    <li>
                      <a href="#"><i class="mdi mdi-facebook"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="mdi mdi-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="mdi mdi-instagram"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="mdi mdi-youtube"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h5 class="team-title font-weight-medium font-18">
                  {{ expert.name }}
                </h5>
                <p class="team-subtitle">{{ expert.profession }}</p>
              </div>
              <p class="mt-4 description">
               {{expert.description}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      experts: [
        {
          name: "Д. Батболд",
          imageUrl: "./homes/expert1.jpg",
          profession: "Спорт аялал жуулчлалын эксперт",
          description:
            "You can relay on our amazing features list and also our customer services will be great experience.",
          url: "https://www.facebook.com/dorjbatbold2",
        },
        {
          name: "Л.Баяндорж",
          imageUrl:
            "./homes/expert2.jpg",
          profession: "Local guide",
          description:
            "Mongolian logistic company, local guide at Daniel Kordan, Care for Mongolia project, Owner and CEO at Nomads Adventure Tours",
          url: "https://www.facebook.com/bayka.cavasi",
        },
        {
          name: "Баяр, Балганцэрэн",
          imageUrl:
            "./homes/expert3.jpg",
          profession: "Байгалийн зурагчин, аялагч",
          description:
            "You can relay on our amazing features list and also our customer services will be great experience.",
          url: "https://www.facebook.com/tertbayar",
        },
        {
          name: "Баттулга, Дамиран",
          imageUrl:
            "./homes/expert4.jpg",
          profession: "Байгалийн зурагчин, аялагч",
          description:
            "You can relay on our amazing features list and also our customer services will be great experience.",
          url: "https://www.facebook.com/battulga.damiran",
        },
      ],
    };
  },
};
</script>
<style>
.team-card .social-overlay {
  position: relative;
}

.team-card .social-overlay .img-overlay {
  background: rgba(26, 139, 243, 0.87);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.team-card .team-title {
  margin: 30px 0 0;
}
.font-18 {
  font-size: 18px;
}
.team-card .team-subtitle {
  margin: 0 0 20px;
  font-size: 13px;
}
</style>